import { createRef, useEffect, useRef, useState } from "react";
import { CHAIN_NAMESPACES, IProvider, WALLET_ADAPTERS, WEB3AUTH_NETWORK } from "@web3auth/base";
import { Web3Auth, Web3AuthOptions } from "@web3auth/modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import "./App.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
// import RPC from "./ethersRPC"; // for using ethers.js

// Providers
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";

// Wallet Services
import { WalletServicesPlugin } from "@web3auth/wallet-services-plugin";

// Adapters
import { HttpClient, USER_TOKEN_KEY } from "./network/http";
import { LoginResponse, UpdateReferrerResponse, ValidateQuestionResponse, ValidateResultResponse } from "./network/types";
import { ethers } from "ethers";
import { ZIM, ZIMEventOfReceiveConversationMessageResult, ZIMLoginConfig, ZIMMessage, ZIMRoomAdvancedConfig } from 'zego-zim-web';
import { getDefaultExternalAdapters } from "@web3auth/default-evm-adapter";

const clientId = "BO2TpJ7iss405l81odQa4NU4XjANm9wIB1mrT1WGVCuSxUwXhjTvbYA_wkeyVRTz6qubPY2Kdk1p5ugvV91pSB0"; // get from https://dashboard.web3auth.io

const chainConfig = {
  chainId: "0xae3f3", // Please use 0x1 for Mainnet
  rpcTarget: "https://evm-rpc-arctic-1.sei-apis.com",
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  displayName: "Sei V2 Devnet",
  blockExplorerUrl: "https://seitrace.com/",
  ticker: "SEI",
  tickerName: "Sei",
  logo: "https://assets.coingecko.com/coins/images/28205/standard/Sei_Logo_-_Transparent.png?1696527207",
};

const contractAddress = "0xCFE0D2F1D4ca4F9cdcc6cfa26eFB00ACa906d7B8"
const abi = [
  "function isUserInGame(uint gameId, address account) public view returns (bool)",
  "function joinGame(uint gameId) external payable",
  "function buyinAmount() public view returns (uint256)"]
  
const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });

const web3AuthOptions: Web3AuthOptions = {
  clientId,
  web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
  uiConfig: {
    uxMode: "redirect",
    appName: "Seiyan Trivia",
    appUrl: "https://web3auth.io/",
    theme: {
      primary: "#7ed6df",
    },
    logoLight: "https://web3auth.io/images/web3authlog.png",
    logoDark: "https://web3auth.io/images/web3authlogodark.png",
    defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl, tr
    mode: "auto", // whether to enable dark mode. defaultValue: auto
    useLogoLoader: true,
  },
  privateKeyProvider: privateKeyProvider,
  sessionTime: 86400 * 7, // 7 days
  // useCoreKitKey: true,
};

const getZIM = () => ZIM;
getZIM().create({
  appID: 1133890363,
});
const zim: ZIM = getZIM().getInstance() as ZIM;

enum JoinGameState {
  LOADING,
  JOINING,
  YES,
  NO
}

enum GameState {
  WAITING,
  COUNTDOWN,
  PLAYING,
  SHOW_RESULT
}

function App() {
  const [web3auth, setWeb3auth] = useState<Web3Auth | null>(null);
  const [walletServicesPlugin, setWalletServicesPlugin] = useState<WalletServicesPlugin | null>(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userAddress, setUserAddress] = useState<string>("");
  const [userFullAddress, setUserFullAddress] = useState<string>("");
  const [userPoints, setUserPoints] = useState<number>(0);
  const [referrer, setReferrer] = useState<string>("");
  const [isEditingReferrer, setIsEditingReferrer] = useState(false);
  const [userBalance, setUserBalance] = useState<string>("");
  const [countdownDay, setCountdownDay] = useState<string>("??");
  const [countdownHour, setCountdownHour] = useState<string>("??");
  const [countdownMinute, setCountdownMinute] = useState<string>("??");
  const [countdownSecond, setCountdownSecond] = useState<string>("??");
  const [currentRoundNumber, setCurrentRoundNumber] = useState<number>(0);
  const [question, setQuestion] = useState<string>("");
  const [choices, setChoices] = useState<string[]>([]);
  const [userId, setUserId] = useState<string>("");
  const [selectedChoice, setSelectedChoice] = useState<number>(-1);
  const [nextGameStartTimeState, setNextGameStartTimeState] = useState<number>(0);
  const [nextGameId, setNextGameId] = useState<number>(0);
  const [hasJoinedGame, setHasJoinedGame] = useState<JoinGameState>(JoinGameState.LOADING);
  const [gameState, setGameState] = useState<GameState>(GameState.WAITING);
  const [timeLeftForQuestionForDisplay, setTimeLeftForQuestionForDisplay] = useState<number>(0);
  const [timeLeftPercentage, setTimeLeftPercentage] = useState<number>(1);
  const [hasWonGame, setHasWonGame] = useState(false);
  const [showSettingsPage, setShowSettingsPage] = useState(false);

  const selectedChoiceRef = useRef<number>()
  selectedChoiceRef.current = selectedChoice

  const hasJoinedGameRef = useRef<JoinGameState>()
  hasJoinedGameRef.current = hasJoinedGame

  const musicPlayerRef = createRef<HTMLAudioElement>()

  const nextGameStartTime = useRef<number>()
  nextGameStartTime.current = nextGameStartTimeState

  const gameIdRef = useRef<number>()
  gameIdRef.current = nextGameId

  const gameRoomId = "1000";
  const questionCountdownUpdateInterval = 200

  const referrerInputRef = createRef<HTMLInputElement>();

  let isAnsweringQuestions = false;
  let isPlayingGame = false;
  let maxTimePerQuestion = 0;
  let timeLeftForQuestion = 0;
  let zimToken = "";
  
  useEffect(() => {
    const init = async () => {
      zim.on('receiveRoomMessage', (zim: any, message: ZIMEventOfReceiveConversationMessageResult) => {
        handleMessage(message.messageList)
      });
      zim.on('error', (e) => { console.log(e) })

      try {
        const web3auth = new Web3Auth(web3AuthOptions as Web3AuthOptions);

        const openloginAdapter = new OpenloginAdapter({
          loginSettings: {
            mfaLevel: "none",
          },
          adapterSettings: {
            uxMode: "redirect", // "redirect" | "popup"
            whiteLabel: {
              logoLight: "https://pbs.twimg.com/profile_images/1770835423666810880/MHHocFs__400x400.jpg",
              logoDark: "https://pbs.twimg.com/profile_images/1770835423666810880/MHHocFs__400x400.jpg",
              defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl, tr
              mode: "light", // whether to enable dark, light or auto mode. defaultValue: auto [ system theme]
            },
          },
        });
        web3auth.configureAdapter(openloginAdapter);

        // Wallet Services Plugin
        const walletServicesPlugin = new WalletServicesPlugin();
        setWalletServicesPlugin(walletServicesPlugin);
        web3auth.addPlugin(walletServicesPlugin);

        // read more about adapters here: https://web3auth.io/docs/sdk/pnp/web/adapters/

        // Only when you want to add External default adapters, which includes WalletConnect, Metamask, Torus EVM Wallet
        const adapters = await getDefaultExternalAdapters({ options: web3AuthOptions });
        adapters.forEach((adapter) => {
          web3auth.configureAdapter(adapter);
        });

        // adding wallet connect v2 adapter
        // const defaultWcSettings = await getWalletConnectV2Settings("eip155", ["1"], "04309ed1007e77d1f119b85205bb779d");
        // const walletConnectV2Adapter = new WalletConnectV2Adapter({
        //   ...(web3AuthOptions as WalletConnectV2AdapterOptions),
        //   adapterSettings: { ...defaultWcSettings.adapterSettings },
        //   loginSettings: { ...defaultWcSettings.loginSettings },
        // });
        // web3auth.configureAdapter(walletConnectV2Adapter);

        // // adding metamask adapter
        // const metamaskAdapter = new MetamaskAdapter(web3AuthOptions as MetamaskAdapterOptions);
        // web3auth.configureAdapter(metamaskAdapter);

        // // adding coinbase adapter
        // const coinbaseAdapter = new CoinbaseAdapter(web3AuthOptions as CoinbaseAdapterOptions);
        // web3auth.configureAdapter(coinbaseAdapter);

        setWeb3auth(web3auth);

        await web3auth.initModal({
          modalConfig: {
            [WALLET_ADAPTERS.OPENLOGIN]: {
              label: "openlogin",
              loginMethods: {
                twitch: {
                  name: "twitch",
                  showOnModal: false
                },
                facebook: {
                  name: "facebook",
                  showOnModal: false
                },
                reddit: {
                  name: "reddit",
                  showOnModal: false
                },
                weibo: {
                  name: "weibo",
                  showOnModal: false
                },
                line: {
                  name: "line",
                  showOnModal: false
                },
                linkedin: {
                  name: "linkedin",
                  showOnModal: false
                },
                kakao: {
                  name: "kakao",
                  showOnModal: false
                },
                farcaster: {
                  name: "farcaster",
                  showOnModal: false
                },
                github: {
                  name: "github",
                  showOnModal: false
                },
                // Disable email_passwordless and sms_passwordless
                email_passwordless: {
                  name: "email_passwordless",
                  showOnModal: false
                },
                sms_passwordless: {
                  name: "sms_passwordless",
                  showOnModal: false
                }
              }
            },
            [WALLET_ADAPTERS.COINBASE]: {
              label: "coinbase",
            }
          }
        });
        if (web3auth.connected) {
          const token = localStorage.getItem(USER_TOKEN_KEY)
          const userInfo = await web3auth.getUserInfo()
          if (token === null || token.length === 0) {
            const userToken = userInfo.idToken!
            localStorage.setItem('USER_TOKEN_KEY', userToken)
          }

          if (!loggedIn) {
            loginBackend();
          } else {
            allUpdates(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);

  // IM message handling
  const handleMessage = (messageList: ZIMMessage[]) => {
    console.log("handleMessage", {messageList})

    for (const message of messageList) {
      if (!message || !message.message) {
        continue
      }

      const decodedMessageString = decodeURIComponent(message.message as string)

      console.log("decodedMessageString", { decodedMessageString })

      const messageBody = JSON.parse(decodedMessageString)
      const messageType = messageBody["messageType"]
      if (!messageType) {
        continue
      }

      if (messageType === "GAME_COUNTDOWN") {
        if (hasJoinedGameRef.current !== JoinGameState.YES) {
          toast.error("Game started, but you haven't paid for sign up.")
          return
        }
        
        isAnsweringQuestions = false
        isPlayingGame = true
        setGameState(GameState.COUNTDOWN)
      } else if (messageType === "ROUND_INFO") {
        if (!isPlayingGame) {
          console.log("skipping ROUND_INFO because game is over", { message })
          return
        }

        const roundInfo = messageBody["roundInfo"]

        const hasCorrectAnswer = (selectedChoiceRef.current === roundInfo["lastRoundAnswer"])

        setQuestion(roundInfo["question"])
        setChoices(roundInfo["choices"])
        setSelectedChoice(-1)
        maxTimePerQuestion = roundInfo["maxTimeLeft"] * 1000
        timeLeftForQuestion = maxTimePerQuestion
        setCurrentRoundNumber(roundInfo["roundNumber"])

        if (roundInfo["roundNumber"] === 0) {
          isAnsweringQuestions = true
          setGameState(GameState.PLAYING)
        } else if (!hasCorrectAnswer) {
          isAnsweringQuestions = false
          isPlayingGame = false
          setHasWonGame(false)
          setGameState(GameState.SHOW_RESULT)
          validateLoseGame()
        }
        
        document.body.style.backgroundColor = "#0cf"
      } else if (messageType === "GAME_OVER") {
        if (!isPlayingGame) {
          console.log("skipping GAME_OVER because game is over", { message })
          return
        }

        isAnsweringQuestions = false
        isPlayingGame = false
        const isWinner = selectedChoiceRef.current === messageBody["lastRoundAnswer"]
        setHasWonGame(isWinner)
        setGameState(GameState.SHOW_RESULT)
        validateLoseGame()

        if (isWinner) {
          HttpClient.post<ValidateResultResponse>('game/validate_winner', {})
            .then((response) => {
              const responseData = response.data
              console.log("validate_winner", {responseData})

              if (!responseData.insertedNewEntry) {
                toast.error("You are not a winner.");
              }
            })
            .catch((e) => {
              toast.error("Failed to validate as winner. Try again");
            })
        }
      }
    }
  }

  const validateLoseGame = () => {
    HttpClient.post<ValidateResultResponse>('game/validate_loser', {})
      .then((response) => {
        const responseData = response.data
        console.log("validate_loser", {responseData})

        if (!responseData.insertedNewEntry) {
          toast.error("Fail to save your game result.");
        }
      })
      .catch((e) => {
        toast.error("Failed to validate as winner. Try again");
      })
  }

  const getBackToHomeScreen = () => {
    document.body.style.backgroundColor = "#fff"
    setSelectedChoice(-1)
    setChoices([])
    setQuestion("")
    isAnsweringQuestions = false
    isPlayingGame = false
    musicPlayerRef.current!.muted = true
    setGameState(GameState.WAITING)
    setHasJoinedGame(JoinGameState.NO)
    allUpdates(false)
  }

  // All updates
  const allUpdates = async (skipUpdateGameCountdown: boolean) => {
    await HttpClient.get<LoginResponse>('user/all_updates')
      .then((response) => {
          const responseData = response.data
          console.log("allUpdates", {responseData})

          setGameState(GameState.WAITING)
          setUserId(responseData.userId)
          getBalance(responseData.address)
          setNextGameStartTimeState(responseData.nextGameStartTime)
          setNextGameId(responseData.gameId)
          updateGameState(responseData.address, responseData.gameId)
          zimToken = responseData.zimToken
          if (skipUpdateGameCountdown) {
            updateGameCountdown()
          }
          setInterval(updateQuestionCountdown, questionCountdownUpdateInterval)
          setUserAddress(getDisplayedAddress(responseData.address))
          setUserFullAddress(responseData.address)
          setUserPoints(responseData.points)
          setReferrer(getReferrerIdForDisplay(responseData.referrer))
          loginIM(responseData.address)
        }
      )
      .catch((error) => {
        if (error.response?.status === 403) {
          console.log("blocked because of invalid region")
          window.location.href = "/blocked.html"
        } else {
          toast.error("Failed to refresh. Try again")
          console.log(error)
        }
      })
  }

  const loginIM = async(address: string) => {
    const shortAddress = address.substring(0, 32)
    console.log("loginIM", {shortAddress, zimToken})
    zim.login(shortAddress, {
      token: zimToken,
      isOfflineLogin: false
    } as ZIMLoginConfig)
    .then(() => {
      console.log("Logged into zim", {shortAddress})
      zim.enterRoom({ roomID: gameRoomId, roomName: "trivia_room" }, {} as ZIMRoomAdvancedConfig)
        .then(() => {
          console.log("Logged into room " + gameRoomId)
        })
        .catch((e) => {
          toast.error("Failed to connect to game room. Try again");
          console.log(e)
        })
    })
    .catch((e) => {
      toast.error("Failed to connect. Try again. " + e);
      console.log(e)
    })
  }

  const loginBackend = async () => {
    await HttpClient.post<LoginResponse>('user/login', {})
      .then((response) => {
        const responseData = response.data
        console.log("loginBackend", {responseData})

        setGameState(GameState.WAITING)
        setUserId(responseData.userId)
        getBalance(responseData.address)
        setNextGameStartTimeState(responseData.nextGameStartTime)
        setNextGameId(responseData.gameId)
        updateGameState(responseData.address, responseData.gameId)
        zimToken = responseData.zimToken
        updateGameCountdown()
        setInterval(updateQuestionCountdown, questionCountdownUpdateInterval)
        setUserAddress(getDisplayedAddress(responseData.address))
        setUserFullAddress(responseData.address)
        setUserPoints(responseData.points)
        setReferrer(getReferrerIdForDisplay(responseData.referrer))
        setLoggedIn(true)
        loginIM(responseData.address)
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          console.log("blocked because of invalid region")
          window.location.href = "/blocked.html"
        } else {
          toast.error("Failed to refresh. Try again")
          console.log(error)
        }
      })
  }

  const getDisplayedAddress = (address: string) => {
    return "sei" + address.substring(0, 4) + "..." + address.substring(address.length - 5)
  }

  const login = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    await web3auth.connect();
  };

  const logout = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    try {
      await zim.logout();
      await web3auth.logout();
    } catch(e) {
      console.log(e);
    }
    
    zimToken = "";
    setLoggedIn(false);
    setHasJoinedGame(JoinGameState.LOADING);
    setNextGameStartTimeState(0);
    setNextGameId(0);
    setUserAddress("");
    setUserFullAddress("");
    setUserBalance("");
    setReferrer("");
    setUserId("");
    setUserPoints(0);
    localStorage.clear();
  };

  const showSettings = () => {
    setShowSettingsPage(true)
  }

  const exitSettings = () => {
    setShowSettingsPage(false)
    setIsEditingReferrer(false)
  }

  const showWalletUi = async () => {
    if (!walletServicesPlugin) {
      console.log("torus plugin not initialized yet");
      return;
    }
    await walletServicesPlugin.showWalletUi();
  };

  const updateGameCountdown = () => {
    let t = 0
    if (nextGameStartTime && nextGameStartTime.current! > 0) {
      const t = Math.max(0, nextGameStartTime.current! - Math.floor(Date.now() / 1000))
      let seconds = Math.floor(t % 60)
      let minutes = Math.floor((t / 60) % 60)
      let hours = Math.floor((t / (60 * 60)) % 24)
      let days = Math.floor(t / (60 * 60 * 24))
      setCountdownDay(('0' + days).slice(-2))
      setCountdownHour(('0' + hours).slice(-2))
      setCountdownMinute(('0' + minutes).slice(-2))
      setCountdownSecond(('0' + seconds).slice(-2))
    } else {
      setCountdownDay('??')
      setCountdownHour('??')
      setCountdownMinute('??')
      setCountdownSecond('??')
    }

    setTimeout(updateGameCountdown, 1000)
  }

  const updateQuestionCountdown = () => {
    if (!isAnsweringQuestions) {
      return
    }
    
    timeLeftForQuestion = Math.max(0, timeLeftForQuestion - questionCountdownUpdateInterval)
    setTimeLeftForQuestionForDisplay(Math.ceil(timeLeftForQuestion / 1000))
    setTimeLeftPercentage(timeLeftForQuestion / (maxTimePerQuestion - questionCountdownUpdateInterval) * 100)
  }

  const getBalance = async (address: string) => {
    if (!address) {
      return
    }

    const nativeBalance = await new ethers.BrowserProvider(privateKeyProvider).getBalance(address)
    setUserBalance(Number(ethers.formatUnits(nativeBalance)).toFixed(2))
  };

  const updateGameState = async (address: string, gameIdToJoin: number) => {
    setHasJoinedGame(JoinGameState.LOADING)

    console.log("updateGameState", {address, gameIdToJoin})
    const clearingHouseContract = new ethers.Contract(contractAddress, abi, new ethers.BrowserProvider(privateKeyProvider))
    try {
      console.log("isUserInGame", {gameIdToJoin, address})
      const hasJoinedGame = await clearingHouseContract.isUserInGame(gameIdToJoin, address)
      setHasJoinedGame(hasJoinedGame ? JoinGameState.YES : JoinGameState.NO)
    } catch(e) {
      toast("Failed to decide whether you have joined the game. Refresh the page.")
      console.log(e)
    }
  }

  const joinGame = async() => {
    if (hasJoinedGame === JoinGameState.YES) {
      return
    }

    if (!userFullAddress || userFullAddress.length === 0) {
      console.log("userFullAddress", {userFullAddress})
      toast.error("Failed to get your wallet address. Wait a few seconds and try again");
      return
    }

    const gameIdToJoin = gameIdRef.current!
    console.log("updateGameState joinGame", {userFullAddress, gameIdToJoin})
    if (gameIdToJoin === 0) {
      console.log("gameIdToJoin is 0")
      toast.error("Failed to join game (invalid GameId). Refresh page and try again.");
      return
    }

    setHasJoinedGame(JoinGameState.JOINING)

    try {
      const nativeBalance = await new ethers.BrowserProvider(privateKeyProvider).getBalance(userFullAddress)
      
      const ethersProvider = new ethers.BrowserProvider(privateKeyProvider)
      const signer = await ethersProvider.getSigner()

      const clearingHouseContract = new ethers.Contract(contractAddress, abi, signer)
      const buyinAmount = await clearingHouseContract.buyinAmount()

      // TODO: don't hardcode gas
      const minBalance =  Number(ethers.formatUnits(buyinAmount)) + 0.1
      const convertedNativeBalance = Number(ethers.formatUnits(nativeBalance))
      if (convertedNativeBalance < minBalance) {
        setHasJoinedGame(JoinGameState.NO)
        toast.error("Pleast top up your wallet to at least " + minBalance.toFixed(2) + " Sei")
        return
      }

      const gasPrice = await ethersProvider.getFeeData()

      // TODO: estimate gas limit
      const txn = await clearingHouseContract.joinGame(gameIdToJoin, {value: buyinAmount, gasPrice: gasPrice.gasPrice, gasLimit: 100000})
      await txn.wait()
      await getBalance(userFullAddress)
      setHasJoinedGame(JoinGameState.YES)
    } catch (err) {
      console.log(err)

      setHasJoinedGame(JoinGameState.NO)
      toast.error("Failed to join game. " + err)
    }
  }

  const validateQuestion = async(clickedRoundNumber: number, index: number) => {
    if (selectedChoice === index) {
      return
    }

    if (musicPlayerRef.current!.muted) {
      musicPlayerRef.current!.currentTime = 0
      musicPlayerRef.current!.muted = false
      musicPlayerRef.current!.play()
    }
    
    setSelectedChoice(index)

    setTimeout(() => {
      HttpClient.post<ValidateQuestionResponse>('game/validate_answer', {roundNumber: String(clickedRoundNumber), answer: String(index)})
        .then((response) => {
          const responseData = response.data
          if (responseData.errorMessage && responseData.errorMessage !== "") {
            setSelectedChoice(-1)
            toast.error(responseData.errorMessage)
            console.log(responseData.errorMessage)

            return
          }
        })
        .catch((e) => {
          setSelectedChoice(-1)
          toast.error("Failed to submit answer. Try again")
          console.log(e)
        })
    }, 50)
  }

  const countDownAnimationView = (
    <div>
      <img className="countdown-gif" src="/ready_no_loop.gif"></img>
    </div>
  )

  const countDownAndJoinView = (
    <>
      <div className="countdown-container">
        <div className="countdown-intro">
          Starting in...
        </div>
        <div className="countdown-box">
          <div>
            <span className="days">{countdownDay}</span>
            <div className="smalltext">Days</div>
          </div>
          <div>
            <span className="hours">{countdownHour}</span>
            <div className="smalltext">Hours</div>
          </div>
          <div>
            <span className="minutes">{countdownMinute}</span>
            <div className="smalltext">Minutes</div>
          </div>
          <div>
            <span className="seconds">{countdownSecond}</span>
            <div className="smalltext">Seconds</div>
          </div>
        </div>
      </div>
      <div className="join-game-container">
        {
          (hasJoinedGame === JoinGameState.LOADING) && 
          <button className="card" id="join-game-btn" disabled={true}>Loading...</button>
        }

        {
          (hasJoinedGame === JoinGameState.JOINING) && 
          <button className="card" id="join-game-btn" disabled={true}>Joining...</button>
        }

        {
          (hasJoinedGame === JoinGameState.YES) && 
          <button className="card" id="join-game-btn">You've joined next game</button>
        }

        {
          (hasJoinedGame === JoinGameState.NO) && 
          <button className="card" onClick={joinGame} id="join-game-btn">Join next game</button>
        }
      </div>
    </>
  )

  const saveReferrer = () => {
    if (!referrerInputRef.current || referrerInputRef.current === null || referrerInputRef.current.value.length === 0) {
      toast.error("please enter a valid referral code.")
      return
    }

    const referralId = referrerInputRef.current.value
    HttpClient.post<UpdateReferrerResponse>('user/referral', {referrerAddress: referralId})
      .then((response) => {
        toast.success("Referrer saved!")
        setReferrer(getReferrerIdForDisplay(referralId))
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.errorMessage
        console.log(error.response?.data)
        if (errorMessage) {
          toast.error(errorMessage)
          return
        } else {
          toast.error("Failed to save referrer.")
          return
        }
      })
    
    setIsEditingReferrer(false)
  }

  const getReferrerIdForDisplay = (referralId: string | null | undefined) => {
    if (!referralId || referralId === null || referralId.length === 0) {
      return "None"
    }

    return referralId.substring(0, 4) + "..." + referralId.substring(referralId.length - 4, referralId.length)
  }

  const copyUserId = () => {
    navigator.clipboard.writeText(userId)
    toast.success("Referral ID copied!")
  }

  const menuView = (
    <div className="homepage-menu">
      <div className="points" >Points: {userPoints}</div>
      {
        isEditingReferrer ? (
          <div className="referrer" >Referer ID: <input ref={referrerInputRef} className="referrer-input"/> <span className="referrer-edit" onClick={saveReferrer}>Save</span></div>
        ) : (
          <div className="referrer" >Referer ID: {referrer} <span className="referrer-edit" onClick={() => setIsEditingReferrer(true)}>Edit</span></div>
        )
      }
      <div className="referrer" >Your Refer ID: {(userId && userId !== null && userId.length > 0) ?
        userId.substring(0, 4) + "..." + userId.substring(userId.length - 4, userId.length) : "None"
        }<span className="referrer-edit" onClick={copyUserId}>Copy</span></div>
      <button className="logout-btn" onClick={logout}>Log out</button>
    </div>
  );

  const loggedInView = (
    <div className="landing-page">
      <div className="landing-top-panel">
        {
          showSettingsPage ? (
            <div className="user-balance homepage-back-button" onClick={exitSettings}>
              <img src="/back_button.png"></img>
              <div>Back</div>
            </div>
          ) : (
            <div className="user-balance">
              <img src="/SeiLogo.png"></img>
              <div>{userBalance}</div>
            </div>
          )
        }
        {
          showSettingsPage ? (<></>) : (
            <div className="user-wallet" onClick={showWalletUi}>
              <img src="/wallet_icon.png"></img>
              <div>{userAddress}</div>
            </div>
          )
        }
        {
          showSettingsPage ? (<></>) : (
            <div className="settings-link" onClick={showSettings}>
              Settings
            </div>
          )
        }
      </div>
      
      {
        gameState === GameState.COUNTDOWN ? countDownAnimationView : 
          (showSettingsPage ? menuView : countDownAndJoinView)
      }
    </div>
  );

  const unloggedInView = (
    <div className="loggin-page">
      <div>
        <img className="landing-logo" src="/logo_360.jpg"></img>
      </div>

      <div className="landing-slogan">Answer Trivia, Win Money</div>
      
      <button onClick={login} className="card" id="landing-login-btn">
        Login
      </button>
    </div>
  );

  const gameView = (
    <div className="game-container">
      <CircularProgressbar className="question-countdown" counterClockwise={true} styles={buildStyles({textSize: "6vh", textColor: "#000000", pathColor: "#00bf96"})}
        value={timeLeftPercentage} text={`${timeLeftForQuestionForDisplay}`} />
      <div className="question">{question}</div>
      <div className="choice-container">
        {choices.map((choice, i) => <button className={selectedChoice === i ? "card select-choices" : "card choices"} key={i} onClick={() => validateQuestion(currentRoundNumber, i)}>{choice}</button>)}
      </div>
      </div>
  )

  const gameOverView = (
    <div className="game-over-container">
      {
        hasWonGame ? (
          <div>
            <img className="game-over-gif win-gif" src="/win.gif"></img>
            <div className="win-text">Your rewards will be distributed shortly.</div>
          </div>
        ) :
        (
          <img className="game-over-gif lose-gif" src="/wrong_answer.gif"></img>
        )
      }
      <button className="card leave-game" onClick={getBackToHomeScreen}>Exit Game</button>
    </div>
  )

  const render = () => {
    if (!loggedIn) {
      return unloggedInView
    }

    if (gameState === GameState.PLAYING) {
      return gameView
    }

    if (gameState === GameState.SHOW_RESULT) {
      return gameOverView
    }

    return loggedInView
  }

  return (
    <div>
      {
      render()
     }
     <ToastContainer position="top-center"/>
     <audio ref={musicPlayerRef} src="https://d3lchi6pym9t35.cloudfront.net/lead-songs/background.mp3" autoPlay={true} muted={true}></audio>
    </div>
  );
}

export default App;
